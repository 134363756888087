.component{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
   
   
    text-align: left;
}

.input:disabled {
    font-family: 'InterRegular';
    font-size: 14px;
    background-color: transparent;
    padding-left: 10px;
    
    
    border: 1px solid  transparent;
    border-radius: 20px;
    color: black;
    height: 34px;
    width: calc(100% - 10px);
}
  

.input {
    font-family: 'InterRegular';
    font-size: 14px;
    background-color: white;
    padding-left: 10px;
    
    
    border: 1px solid  gray;
    border-radius: 10px;
    color: black;
    height: 34px;
    width: calc(100% - 10px);
}

.input:active {
    font-family: 'InterRegular';
    font-size: 14px;
    background-color: white;
    padding-left: 10px;
    
    border: 1px solid  gray;
    border-radius: 10px;
    color: black;
    height: 34px;
    width: calc(100% - 10px);
}

.title{
    font-family: 'InterRegular';
    font-size: 12px;
    margin-left: 7px;
    color: black;
    margin-bottom: 3px;
    
}