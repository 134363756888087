.component  {
    position: absolute;
    border-radius: 0px 0px 10px 10px;
    top: -100px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 40px;
    background-color: rgb(226, 61, 61);
    box-shadow: 0px 3px 20px #060020BC;

    text-align: center;

    font-size: 20px;
    color: white;

    transition: top 0.5s;
}