.component{
    transition: 0.3s;
    height:60px;
    background: transparent linear-gradient(93deg, #9AE4E6 0%, #DAE228 100%) 0% 0% no-repeat padding-box;
    font-family: 'InterBold';
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    opacity: 0.8;    
    color: black;
}

.component:hover{
    transition: 0.3s;
    height:60px;
    background: transparent linear-gradient(93deg, #9AE4E6 0%, #DAE228 100%) 0% 0% no-repeat padding-box;
    font-family: 'InterBold';
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    opacity: 1;
}



